import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Boxes from '../loader/Boxes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {Home} from '../../global/Home';
import {Link} from 'react-router-dom';


class ReportContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        clicked: true,
        counter: 0,
        counter2: 0,
        status: true,
        country: [],
        Wines:[],
        message: [],
        dname: "",
        demail:"",
        demailError:"",
        dcountry:"",
        desc:"",
        descError:"",
        dnameError: "",
        dcountryError:"",
        reason:"",
        reasonError:"",
        formReady:true,
        uploadprogress: null,
        done:false,
        drink:[],
        name:[]

      }
      this.handleChange = this.handleChange.bind(this);
      this.FindParam = this.FindParam.bind(this);
    }

     FindParam(){
         const id = this.props.id;
        axios.get(Home+`HomeDrinks/`+id)
          .then(res => {
            const drinks = res.data;
            this.setState({ name:drinks.name });
          }).catch(err =>console.log(err));
    }

    componentDidMount() {
        this.FindParam();
      }

      handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        }else{
          this.setState({
            frontfile: this.fileInput.current.files[0],
            backfile: this.fileInput2.current.files[0]});
        }
      }

      ChaneBTN =(bool)=>{
        this.setState({formReady:bool});
     }

     handleError = (name)=>{
        this.ChaneBTN(false);
      
      
        if (name === 'dname') {
            if (this.state[name] === "") {
              this.setState({dnameError: 'Name is required'});
            }else{
              this.setState({dnameError: ''});
            }
        }else if(name === 'dcountry'){
            if (this.state[name] === "") {
              this.setState({dcountryError: 'Country is required'});
            }else{
              this.setState({dcountryError: ''});
            }
        }else if(name === 'demail'){
            if (this.state[name] === "") {
              this.setState({demailError: 'Email is required'});
            }else if(this.state[name] !=="" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state[name])){

            }else{
              this.setState({demailError: ''});
            }
        }else if(name === 'desc'){
            if (this.state[name] === "") {
              this.setState({descError: 'Meaage is required'});
            }else{
              this.setState({descError: ''});
            }
        }else if(name === 'reason'){
            if (this.state[name] === "") {
                this.setState({reasonError: 'Reason is required'});
              }else{
                this.setState({reasonError: ''});
              }
        }
      
        
        if (this.state.dnameError !=="" || this.state.dcountryError !=="" || this.state.demailError !=="" || this.state.descError!=="") {
          return false;
        }else{
          return true;
        }
      
      }

      handleSubmit = (event) =>{
        event.preventDefault();
        const check = this.handleError();
        if (check) {

            if(this.state.counter !== this.state.counter2){
                this.setState({counter2: this.state.counter2-1});
               }
              this.setState({clicked:false});
              axios.post(Home+`sendreport`, 
          { 
            email: this.state.demail,
            name: this.state.dname,
            country: this.state.dcountry,
            message:this.state.desc,
            reason: this.state.reason,
            id:this.props.id
           })
           .then(res => {
           
            this.setState({loading: false,counter2: this.state.counter2+1});
           if(res.status ===200 && res.data.success){
            
            this.setState({logged:true,message:res.data.message,done:true});
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
            this.setState({
              logged:true,
              status:false,
              message:res.data.message});

              toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
          })
        }

      }




    render() {
        
        return (
            <div>
                <div className="container-fluid">
                <ToastContainer 
                 className='toast-container'
                 toastClassName="dark-toast-error"
                 position="top-right"
                 autoClose={55000}
                 hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
               />
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <br></br>
                            <div className="card st-shadow mt-5">
                                <div className="card-header st-bg">
                                    
                                    <h5 className="text-center" style={{fontWeight:'400',paddingTop:'6px'}}>Anything wrong?. Write a complain for this drink</h5>
                                </div>
                                <div className="st-empty2"></div>
                                <div className="card-body">
                                    {this.state.done?'':
                                    <form onSubmit={this.handleSubmit}>
                                
                                <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-user"></span></div>
                                       </div>
                                      <input
                                      onChange={this.handleChange}
                                      value={this.state.name}
                                      type="text" 
                                      className="form-control radius" 
                                      placeholder="Name of Wine"
                                      readOnly />
                                    </div>
                                    </div>

                                    <div className="form-group">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-map-marker"></span></div>
                                       </div>
                                      <select required onBlur={()=>this.handleError('reason')} className="form-control" name="reason" onChange={this.handleChange}>
                                        <option value="">Select Your Reason</option>
                                        <option value="Piracy">Piracy</option>
                                        <option value="My reasons">My reasons</option>
                                      </select>
                                      </div>
                                      <span className="text-danger">{this.state.reasonError}</span>
                                    </div>

                                <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-user"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('dname')}
                                      value={this.state.dname}
                                      name="dname"
                                      type="text" 
                                      className="form-control radius" 
                                      placeholder="Name of Wine"
                                      onChange={this.handleChange}
                                      required />
                                    </div>
                                    <span className="text-danger">{this.state.dnameError}</span>
                                    </div>

                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-envelope-o"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('demail')}
                                      value={this.state.demail}
                                      name="demail"
                                      type="email" 
                                      className="form-control radius" 
                                      placeholder="Entery Your Emal Address"
                                      onChange={this.handleChange}
                                      required />
                                    </div>
                                    <span className="text-danger">{this.state.demailError}</span>
                                    </div>

                                    <div className="form-group">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-map-marker"></span></div>
                                       </div>
                                      <select required onBlur={()=>this.handleError('dcountry')} className="form-control" name="dcountry" onChange={this.handleChange}>
                                        <option value="">Select Country</option>
                                        <option value="International">International</option>
                                        {this.state.country.map(wines=>
                                         <option key={wines.name} value={wines.name}>{wines.name}</option>
                                          )}
                                      </select>
                                      </div>
                                      <span className="text-danger">{this.state.dcountryError}</span>
                                    </div>  

                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-commenting-o"></span></div>
                                       </div>
                                      <textarea 
                                      className="form-control" 
                                      name="desc" 
                                      onBlur={()=>this.handleError('desc')}
                                      value={this.state.desc}
                                      onChange={this.handleChange}
                                      placeholder="Start Typing Your FeedBack....."
                                      required
                                      />
                                      </div>
                                      <span className="text-danger">{this.state.descError}</span>
                                    </div>

                                    <div className="form-group">
                                    {(this.state.clicked ===false && this.state.loading ===true)||(this.state.clicked ===false && this.state.counter === this.state.counter2)? <center> <Boxes /> </center>:
                                      <button disabled={this.state.formReady} className="btn btn-block st-bg-d">Submit</button>
                                    }
                                    </div>
                                    </form>
                                  }
                                {this.state.done?
                                <div className="mb-4">
                                    <div className="st-empty"></div>
                                    <div className="text-center">
                                        <span className="fa fa-thumbs-o-up" style={{fontSize:'590%',color:'#00008c'}}></span>
                                        <div className="lead">Thanks for the report. We will look into ASAP</div>
                                        <Link to="/" className="btn st-bg-d mt-2 ">Return Home</Link>
                                        
                                        
                                    </div>
                                    <div className="st-empty"></div>
                                </div>
                                :''}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportContainer;