export const fetch_post = () => {
    return {
        type: "SEARCH_PAGE"
    };
};

export const receive_post = post => {
    return {
        type: "SEARCH_CURRENT",
        data: post
    };
};

export const receive_error = () => {
    return {
        type: "RECEIVE_ERROR"
    };
};

export const thunk_action_creator = name => {
    //store.dispatch(fetch_post());
    return function(dispatch, getState) {
        return dispatch(receive_post(name))


    };
};