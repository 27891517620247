import React,{Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Home from  './pages/HomePage';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminDrinkTypes from './pages/AdminDrinkTypes';
import AdminDrinkTypesSub from './pages/AdminDrinkTypesSub';
import AdminPostWine from './pages/AdminPostWine';
import SingleDrink from './pages/SingleDrink';
import SingleTypeDrink from './pages/SingleTypeDrink';
import About from './pages/About';
import Feedback from './pages/Feedback';
import MobileDrinkTypes from './pages/MobileDrinkTypes';
import MobileDrinkTypes2 from './pages/MobileDrinkTypes2';
import Report from './pages/Report';
import SearchPage from './pages/SearchPage';
import ErrorPage from './pages/ErrorPage';


class App extends Component {
 render(){
   return(
     <div>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/about" component={About} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/types/alcoholic_drinks" exact component={MobileDrinkTypes} />
        <Route path="/types/non_alcoholic_drinks" exact component={MobileDrinkTypes2} />
        <Route path="/report/:id" component={Report} />
        <Route path="/drink/:id" component={SingleDrink} />
        <Route path="/type/:id" component={SingleTypeDrink} />
        <Route path="/search/:name" component={SearchPage} />
        <Route path="/whocodedpost/drinktypes" exact component={AdminDrinkTypes} />
        <Route path="/whocodedpost/drinktypes/sub" exact component={AdminDrinkTypesSub} />
        <Route path="/whocodedpost/drinks"  component={AdminPostWine} />
        <Route render={function () {
              return <div className="cover-full">
                    <ErrorPage />
                </div>
            }} />
      </Switch>
     </div>
   )
 }
}

export default App;
