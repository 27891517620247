import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './card.css';
import Index from '../sharebutton/Index';
import axios from 'axios';
import Conatiner from '../loader/Conatiner';
import Boxes from '../loader/Boxes';
import {Home} from '../../global/Home';
import Modal from '../modal/Modal';
import {Link} from 'react-router-dom';
import Scroller from './Scroller';



class Showcase extends Component {

    constructor(props){
        super(props);
        this.state = {
            drinks:[],
            open: false,
            ModalData:[],
            initialPropsId:[],
            isPaginating: false,
            isLoading:false,
            nextPage: [],
            cardmenu:false,
            currentcard:0,
            error:false,
            error1:true,
            sroller:false
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.isBottom = this.isBottom.bind(this);
        this.CardMenu = this.CardMenu.bind(this);
        this.scrollhandler = this.scrollhandler.bind(this);
    }

    openModal(id) {
        this.setState({ open: true, ModalData:id});
      }

    closeModal() {
        this.setState({ open: false });
      }

      isBottom(el) {
          if (el) {
         
          if (window.pageYOffset < 200) {
              return false;
          }
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
    }


    

    componentDidMount() {
      
      axios.get(Home+`HomeDrinks`)
      .then(res => {
        const drinks = res.data;
        const nextPage = res.data[0].nextPageUrl;
        this.setState({ drinks: drinks, nextPage: nextPage });
      })

       document.addEventListener('scroll', this.trackScrolling);

      }

      componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

      trackScrolling = () => {
        if (window.pageYOffset >= 200) {
          this.setState({sroller:true});
        }else{
          this.setState({sroller:false});
        }
        const wrappedElement = document.getElementById('header');
        if (this.isBottom(wrappedElement)) {
            
            let nextPage = this.state.nextPage;
               if (nextPage !==null) {
                if (!this.state.isPaginating) {
                    this.setState({isPaginating:true});
                    axios.get(nextPage)
                    .then(res => {
                      const drinks = this.state.drinks.concat(res.data);
                      const nextPage = res.data[0].nextPageUrl
                      this.setState({isPaginating:false, drinks: drinks, nextPage: nextPage });
                    })
                   }
               }
            
          
        }

      }


      CardMenu(id){
        if (this.state.cardmenu) {
          this.setState({cardmenu:false,currentcard:0});
        }else{
          this.setState({cardmenu:true,currentcard:id});
        }
      }

      scrollhandler(){
        window.scrollTo(0,0);
      }

     

    render() {
        if(this.state.drinks.length === 0 ){
            return(
                <div className="content1">
                 <Conatiner />
                </div>
            )
        }
        return (
            <>
            {this.state.sroller?
            <Scroller scrollup={this.scrollhandler} />
            :''}

            <div className="row no-gutters" id="header">
            <Modal 
            close={this.closeModal} 
            open={this.state.open}
            data={this.state.ModalData} />
            {this.state.drinks.map(drink =>
            <div className="col-md-6" key={drink.id} >
               <div className="st-gutters">
               <div className="card mb-3 st-card-height">
                 <div className="row no-gutters">
                     <div className="col-md-6 st-mobile-grid" style={{paddingRight: '1.2px'}}>
                     <Link to={'/drink/'+drink.id+'/'+drink.name}>
                     <img src={drink.front_image} className="card-img img-thumbnail st-thumbnail" alt="..." />
                      <div className="card-img-overlay">
                      <span className="badge badge-danger" style={{float: 'right'}}>{drink.company}</span>
                      </div>
                      </Link>
                     </div>
                     <div className="col-md-6 st-mobile-grid" style={{paddingLeft: '1.2px'}}>
                     
                     <img src={drink.back_image} className="card-img img-thumbnail st-thumbnail" alt="..." />
                  
                     <div className="st-top-right st-click" onClick={()=>this.CardMenu(drink.id)}><span className="fa fa-ellipsis-v st-color st-click" style={{color:'#696971'}}></span>
                      <div className="st-dropdown-content" style={{display:this.state.cardmenu && this.state.currentcard ===drink.id?'block':'none'}}>
                      <Link to={'/report/'+drink.id+'/'+drink.name}>Report</Link>
                      </div>
                     </div>
                     </div>
                 </div>
                 <div className="card-body">
            <h4 className="card-title"><Link to={'drink/'+drink.id+'/'+drink.name} style={{color:'inherit'}}> {drink.name.slice(0,22)} {drink.name.length>22?'...':''} </Link> <span title="Quick View" onClick={()=>this.openModal(drink.id)} className="fa fa-eye st-mouse-hover st-card-icon" style={{float:'right'}}></span></h4>
    <p className="card-text">{drink.desc.slice(0,120)} {drink.desc.length>120?'....':''}</p>
                 </div>
                 <div className="card-footer bg-transparent st-border st-h-rotate" style={{padding: '0px'}}>
                     <Index 
                     title={drink.name}
                     desc={drink.desc.slice(0,120)}
                     url={'https://checkdrinks.com/drink/'+drink.id}
                     stringhash={'drinks'} />
                </div>
               </div> 
            </div>
            </div>
            )}
           <div className="col-md-12">
           <div className="mb-4 mt-4" id="st-loader">
               {this.state.isPaginating && this.state.drinks.lenght !== 0?
               <div className="text-center st-empty" style={{paddingBottom:'5%',paddingTop:'5%',paddingLeft:'10px',paddingRight:'10px'}}>
               <div className="spinner-border" role="status" aria-hidden="true"></div>
              </div>
              :''}
           </div>
           <div className="mt-4">
           {this.state.nextPage==null && !this.state.drinks.message?
              <div className="alert alert-warning text-center shadow">
              <p>No Drinks Available Here</p>
              </div>
              :''}
           </div>
           </div>
         </div>
         
         </>
        );
    }
}

export default Showcase;