import React, { Component } from 'react';
import './layout.css';
import axios from 'axios';
import LeftsideLoader from '../loader/LeftSide';
import {Home} from '../../global/Home';
import {Link} from 'react-router-dom';

class Leftside extends Component {
  constructor(props){
    super(props);
    this.state = {
      Wines:[]
   }
   this.LoadData = this.LoadData.bind(this);
  }

LoadData(){
  axios.get(Home+`AllSub_Winetypes`)
    .then(res => {
      const Wines = res.data;
      this.setState({ Wines });
      //console.log(res.data);
    }).catch(err =>console.log(err))
}

componentDidMount() {
 this.LoadData();
    
  }
    render() {

      if (this.state.Wines.length === 0) {
        return(
          <div>
                <div className="wrapper">
               <nav id="sidebar" className="st-scroll mb-5">
          <br></br>
          <LeftsideLoader />
          <br></br>
          </nav>
          </div>
          </div>
        )
      }

        return (
            <div>
                <div className="wrapper">
        <nav id="sidebar" className="st-scroll mb3">
          {this.state.Wines.map(wine =>
            <ul className="list-unstlye components st-list mb-0" key={wine.id}>
              <li>
              <h6 className="side-head">{wine.parent}</h6>
            </li>
            {wine.child.map(child=>
              <li key={child.id}> <Link to={'/type/'+child.id+'/'+child.name}>{child.name}</Link></li>
              )}
            </ul>
            )}
          
        </nav>
    </div>
            </div>
        );
    }
}

export default Leftside;