import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Laptop from '../components/navbar/Laptop';
import './pages.css';
import about2 from '../img/about2.jpg';
import Footer from '../components/containers/layout/Footer';
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';

class About extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>
                        {HeadTitle}About Checkdrinks
                    </title>
                    <meta name="description" content="Checkdrinks is the world largest drinks app" />
                    <meta name="theme-color" content="#00008C" />
                </Helmet>
                <Laptop />
                <div className="container-fluid shadow" style={{padding:'0px'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56386476.295395985!2d-18.824490001402495!3d2.3013187391814336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0baf7da48d0d%3A0x99a8fe4168c50bc8!2sNigeria!5e1!3m2!1sen!2sng!4v1577276866979!5m2!1sen!2sng" width="100%" height="450" title="About Us" style={{border:'0',marginBottom:'-6px'}} ></iframe>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="mt-4">
                                <br></br>
                            </div>
                            <div className="empty"></div>
                        <h2 className="display-4">About Us</h2>
                    <p>Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world. Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world. Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world</p>
                    <p>Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world. Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world. Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world</p>
                    <p>Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world. Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world. Whocoded is world drinks web app.It's meant to keep all documentation of drinks in the world</p>
                        </div>
                        <div className="col-md-4" style={{paddingRight:'0px'}}>
                        <img className="img-fluid shadow" src={about2} alt="...." />
                        </div>
                    </div>
                </div>
                <Footer st-class="footer2" style={{borderRadius: '0px',}} />
            </div>
        );
    }
}

export default About;