import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from 'react-helmet';
import Rightside from '../components/containers/layout/Rightside';
import Leftside from '../components/containers/layout/Leftside';
//import Showcase from '../components/containers/cards/Showcase';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import {useParams} from "react-router";
import { HeadTitle } from '../components/global/Title';
import ShowecaseTypes from '../components/containers/cards/ShowecaseTypes';


function SingleTypeDrink() {
    const {id} = useParams();
  return (
    <div>
      <Helmet>
     <title> {HeadTitle} Types of drinks</title>
    <meta name="description" content="A type of drinks" />
      </Helmet>
      <Laptop />
    <Leftside />
    
    <div id="content">
      <div className="row no-gutters">
          <div className="st-gutters">
           <ShowecaseTypes id={id} />
          </div>

      </div>
      
    <Footer />
    </div>
    <Rightside />
    
    </div>
  );
}

export default SingleTypeDrink;
