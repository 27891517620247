import React, { Component } from 'react';
import './layout.css';
import axios from 'axios';
import img from '../../../img/first.jpg';
import RightsideLoader from '../loader/RightSide';
import{Home} from '../../global/Home';
import {Link} from 'react-router-dom';
import Footer from './Footer';

class Rightside extends Component {
    state = {
      drinks:[]
  }
  
  componentDidMount() {
      axios.get(Home+`HomeDrinksRand`)
        .then(res => {
           const drinks = res.data;
           this.setState({ drinks });
          //console.log(res);
        })
        .catch(err =>console.log(err));
    }


    render() {
      if (this.state.drinks.length === 0) {
        return(
          <div>
                <div className="wrapper">
               <nav id="sidebar2" className="st-scroll mb-5">
          <br></br>
          <RightsideLoader />
          <br></br>
          </nav>
          </div>
          </div>
        )
      }
        return (
            <div>
                <div className="wrapper">
               <nav id="sidebar2" className="st-scroll mb-5">
          <br></br>
          <ul className="list-unstlye components st-list mb-5">
            <li>
              <h6 className="side-head" style={{padding: '1px 5px 1px 10px'}}>Drinks You May Know</h6>
            </li>
            {this.state.drinks.map(drink =>
              <li key={drink.id}>
              <Link to={'/drink/'+drink.id+'/'+drink.name}>
                <img src={drink.front_image} className="st-simg" alt="...." /> {drink.name.slice(0,20)} {drink.name.length>20?'... ':' '}  
                <small className="badge badge-secondary">{drink.type.slice(0,13)} {drink.type.length>13?'..':''} </small>
                </Link>
            </li>
              )}
          </ul>
          <Footer st_class="mb-5" />
          <br></br>
          
        </nav>
        
        </div>
            </div>
        );
    }
}

export default Rightside;