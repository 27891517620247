import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import '../cards/card.css';
import first2 from '../../../img/first.png';


class Conatiner extends Component {
    state = {
        persons:[
            '1',
            '2',
            '3',
            '4'
        ]
    }
    render() {
        return (
            <div className="row no-gutters">
                {this.state.persons.map(person =>
            <div className="col-md-6" key={person}>
               <div className="st-gutters">
               <div className="card mb-3">
                 <div className="row no-gutters">
                     <div className="col-md-6 st-mobile-grid" style={{paddingRight: '1.2px'}}>
                     <img src={first2} className="shine card-img img-thumbnail" style={{boxShadow: '1px 3px 4px #00000000'}} alt="..." />
                      <div className="card-img-overlay">
                      <span className="l-title11 shine" style={{float: 'right'}}></span>
                      </div>
                     </div>
                     <div className="col-md-6 st-mobile-grid" style={{paddingLeft: '1.2px'}}>
                     <img src={first2} className="shine card-img img-thumbnail" style={{boxShadow: '1px 3px 4px #00000000'}} alt="..." />
                     </div>
                 </div>
                 <div className="card-body">
            <p className="card-title l-title4 shine"></p>
                     <p className="card-text l-title3 shine"></p>
                     <p className="card-text l-title2 shine"></p>
                 </div>
                 <div className="card-footer bg-transparent st-border st-h-rotate" style={{padding: '0px'}}>
                     <center className="st-fff" style={{display: 'inline-flex',paddingTop:'5px',marginLeft: '1%'}}>
                     <span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span><span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span><span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span>
                     <span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span><span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span><span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span>
                     <span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span><span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span><span className="l-stimg shine st-ml" style={{boxShadow: '1px 3px 4px #00000000', border: '0px'}}></span>
                     </center>
                 
                </div>
               </div> 
            </div>
            </div>
            )}
            </div>
        );
    }
}

export default Conatiner;