import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { thunk_action_creator_filter } from '../../../store/actions/filter';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SearchHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active:"all"
    }
    this.allfilter = this.allfilter.bind(this);
    this.namefilter = this.namefilter.bind(this);
    this.categoryfilter = this.categoryfilter.bind(this);
    this.companyfilter = this.companyfilter.bind(this);
  }

  allfilter(){
    this.setState({active:"all"});
    let last = this.props.data.lastsearchname;
    if (last.length < 1) {
      toast.error('Please type something', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
      });

      return false;
  }
    this.props.dispatch(thunk_action_creator_filter({'name':last,'filter':'all'}));
  }

  namefilter(){
    this.setState({active:"name"});
    let last = this.props.data.lastsearchname;
    if (last.length < 1) {
      toast.error('Please type something', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
      });

      return false;
  }
    this.props.dispatch(thunk_action_creator_filter({'name':last,'filter':'name'}));
  }

  categoryfilter(){
    this.setState({active:"cat"});
    let last = this.props.data.lastsearchname;
    if (last.length < 1) {
      toast.error('Please type something', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
      });

      return false;
  }
    this.props.dispatch(thunk_action_creator_filter({'name':last,'filter':'cat'}));
  }

  companyfilter(){
    this.setState({active:"company"});
    let last = this.props.data.lastsearchname;
    if (last.length < 1) {
      toast.error('Please type something', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
      });

      return false;
  }
    this.props.dispatch(thunk_action_creator_filter({'name':last,'filter':'company'}));
  }
    render() {
      let all = "";
      let name = "";
      let cat = "";
      let comp = ""
      if (this.state.active === "all") {
        all+="active";
        name+="";
        cat+="";
        comp+="";
      }else if(this.state.active === "name"){
        all+="";
        name+="active";
        cat+="";
        comp+="";
      }else if(this.state.active === "cat"){
        all+="";
        name+="";
        cat+="active";
        comp+="";
      }else if(this.state.active === "company"){
        all+="";
        name+="";
        cat+="";
        comp+="active";
      }
       
      if (window.innerWidth <= 1050) {
        return <div className=" bg-st-primary st-shadow"><ul className="nav nav-pills nav-fill" id="mobile" style={{marginTop: '1.4px'}}>
        <li className="nav-item">
        <Link onClick={this.allfilter} to="#" className={'st-search nav-link '+all}>All filter</Link>
        </li>
        <li className="nav-item">
        <Link onClick={this.namefilter} to="#" className={'st-search nav-link '+name}>Names</Link>
        </li>
        <li className="nav-item">
        <Link onClick={this.categoryfilter} to="#" className={'st-search nav-link '+cat}>Categorise</Link>
        </li>
        <li className="nav-item">
        <Link onClick={this.companyfilter} to="#" className={'st-search nav-link '+comp}>Companies</Link>
        </li>
      </ul>
      <ToastContainer 
        className='toast-container'
        toastClassName="dark-toast-error"
        position="top-right"
        autoClose={55000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
          />
      </div>
      }
        return (
            <div>
               <ToastContainer 
                  className='toast-container'
                  toastClassName="dark-toast-error"
                  position="top-right"
                  autoClose={55000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
               />
              <nav className="nav nav-pills flex-column flex-sm-row shadow bg-st-primary" style={{borderRadius:'9px'}}>
                <Link onClick={this.allfilter} className={'flex-sm-fill text-sm-center nav-link st-search '+all} to="#">All filter</Link>
                <Link onClick={this.namefilter} className={'flex-sm-fill text-sm-center nav-link st-search '+name} to="#">Name</Link>
                <Link onClick={this.categoryfilter} className={'flex-sm-fill text-sm-center nav-link st-search '+cat} to="#">Categorise</Link>
                <Link onClick={this.companyfilter} className={'flex-sm-fill text-sm-center nav-link st-search '+comp} to="#" >Companies</Link>
              </nav>
            </div>
        );
    }
}

const mapStoreToProps = (store) =>{
  return{
     data:store
  }
}

export default connect(mapStoreToProps) (SearchHeader);