export const fetch_post = () => {
    return {
        type: "FETCH_SEARCH"
    };
};

export const receive_post = post => {
    return {
        type: "SEARCH_LASTNAME",
        data: post
    };
};

export const receive_error = () => {
    return {
        type: "RECEIVE_ERROR"
    };
};

export const thunk_action_creator_lastname = name => {

    return function(dispatch, getState) {
        return dispatch(receive_post(name))


    };
};