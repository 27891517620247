const initialState = {
    drinks: [],
    isLoggedIn: false,
    isFetching: false,
    isError: false,
    redirect: false,
    searchpage: false,
    searchopen: false,
    lastsearchname: [],
    nextPage: []
};

const asyncReducer = (state = initialState, action) => {


    if (action.type === "FETCH_SEARCH") {
        return Object.assign({}, state, {
            isLoggedIn: false,
            isFetching: true,
            isError: false,
            redirect: false
        });
    } else if (action.type === "FETCHED_SEARCH") {
        return Object.assign({}, state, {
            drinks: action.data,
            isLoggedIn: true,
            isError: false,
            isFetching: false,
            redirect: true
        });
    } else if (action.type === "RECEIVE_PAGINATE") {
        return Object.assign({}, state, {
            nextPage: action.data
        });
    } else if (action.type === "SEARCH_MORE") {
        return Object.assign({}, state, {
            isFetching: true
        });
    } else if (action.type === "FETCHED_SEARCH_MORE") {
        return Object.assign({}, state, {
            drinks: state.drinks.concat(action.data),
            isFetching: false
        });
    } else if (action.type === "RECEIVE_ERROR") {
        return Object.assign({}, state, {
            isLoggedIn: false,
            isError: true,
            isFetching: false,
            redirect: true
        });
    } else if (action.type === "SEARCH_CURRENT") {
        return Object.assign({}, state, {
            isLoggedIn: false,
            isError: false,
            isFetching: false,
            redirect: false,
            searchpage: action.data
        });
    } else if (action.type === "SEARCH_OPENED") {
        return Object.assign({}, state, {
            searchopen: action.data
        });
    } else if (action.type === "SEARCH_LASTNAME") {
        return Object.assign({}, state, {
            lastsearchname: action.data
        });
    } else if (action.type === "FETCHED_FILTER") {
        return Object.assign({}, state, {
            isFetching: false,
            drinks: action.data
        });
    }
    return state;


};

export default asyncReducer;