import React, { Component } from 'react';
import 'react-material-design';
import 'font-awesome/css/font-awesome.css';
import './style.css';
import Modal from '../modal/Modal';
import {
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

class Index extends Component {
    render() {
        return (
            <div className="justify-content-center" style={{display:'flex',overflowX:'auto',overflowY:'hidden',padding:'6px'}}>
                <FacebookShareButton quote={this.props.title} hashtag={this.props.stringhash} url={this.props.url}>
                <a className="btn-lg btn-default" href="/#"><span className="st-img-h fa fa-facebook-square" ></span></a>
                </FacebookShareButton>
                <WhatsappShareButton title={this.props.title} url={this.props.url} separator=" ">
                <a className="btn-lg btn-default" href="/#"><span className="st-img-h fa fa-whatsapp" ></span></a>
                </WhatsappShareButton>
                <InstapaperShareButton url={this.props.url} title={this.props.title} description={this.props.desc}>
                <a className="btn-lg btn-default" href="/#"><span className="st-img-h fa fa-instagram" ></span></a>
                </InstapaperShareButton>
                <TwitterShareButton title={this.props.title} hashtags={['drinks','alcohol','non-alcohol']} url={this.props.url}>
                <a className="btn-lg btn-default" href="/#"><span className="st-img-h fa fa-twitter" ></span></a>
                </TwitterShareButton>
                <PinterestShareButton url={this.props.url} description={this.props.desc}>
                <a className="btn-lg btn-default" href="/#"><span className="st-img-h fa fa-pinterest-p" ></span></a>
                </PinterestShareButton>
                <LinkedinShareButton url={this.props.url} title={this.props.title} summary={this.props.desc} source={this.props.url}>
                <a className="btn-lg btn-default" href="/#"><span className="st-img-h fa fa-linkedin-square" ></span></a>
                </LinkedinShareButton>
                <Modal />
                
            </div>
        );
    }
}

export default Index;