import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Home} from '../components/global/Home';
import {HeadTitle} from '../components/global/Title';
import Laptop from '../components/navbar/Laptop';
import '../components/containers/layout/layout.css';
import './pages.css';
import {Helmet} from 'react-helmet';
import  { Redirect } from 'react-router-dom';
import Spinner from '../components/containers/loader/Spinner';

class MobileDrinkTypes2 extends Component {
    constructor(props) {
      super(props);
      this.state = {
        Wines:[],
        loading:false
     }
     this.LoadData = this.LoadData.bind(this);
    }
  
  LoadData(){
    this.setState({loading:true});
    axios.get(Home+`AllSub_WinetypesNonAlcohol`)
      .then(res => {
        const Wines = res.data;
        this.setState({ Wines:Wines,loading:false });
      }).catch(err =>console.log(err))
  }
  
  componentDidMount() {
   this.LoadData();
      
    }
    render() {
        if (window.innerWidth >= 768) {
          return <Redirect to="/login" />
        }
        return (
            <div>
                <Helmet>
                <title>
                  {HeadTitle} Non-Alcoholoc Categoryies
                </title>
                <meta name="theme-color" content="#00008C" />
              </Helmet>
                <Laptop />
                <div className="st-empty"></div>
                <div className="mt-4 mb-4">
                <nav className="nav flex-column" style={{textAlign:'center'}}>
                <a className="nav-link disabled st-cccz" href="/#" style={{fontWeight: '600'}}>Non-Alcoholic Categories</a>
                {!this.state.loading? this.state.Wines.map(wine=>
                    <Link key={wine.id} to={'/type/'+wine.id} className="nav-link active st-cccz2">{wine.name}</Link>
                ):
                <span className="mt-5 active" style={{color:'#00008c'}}>
                  <Spinner />
                </span>
                }
          
         </nav>
            </div>
            </div>
        );
    }
}

export default MobileDrinkTypes2;