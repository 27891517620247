import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rightside from '../components/containers/layout/Rightside';
import Leftside from '../components/containers/layout/Leftside';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';
import SearchResults from '../components/containers/cards/SearchResults';
import {connect} from 'react-redux';
import { thunk_action_creator } from '../store/actions/searchpage';

class SearchPage extends Component{
    // const {name} = useParams();
    // let match = useRouteMatch();

    componentDidMount(){
      this.props.dispatch(thunk_action_creator(true));
    }

    componentWillUnmount() {
      this.props.dispatch(thunk_action_creator(false));
    }
    
    render(){
      //console.log(this.props);
    return (
      <div>
        <Helmet>
          <title> {HeadTitle} Search Drink </title>
          <meta name="description" content="Search for a drink you wants" />
        </Helmet>
        <Laptop  />
      <Leftside />
      
      <div id="content">
        <div className="">
            <div className="st-gutters">
             <SearchResults  />
            </div>
  
        </div>
        
      <Footer st_class="st-fixed-footer" />
      </div>
      <Rightside />
      
      </div>
    );
}
}

const mapStoreToProps = (store) =>{
  return{
     data:store
  }
}
export default connect(mapStoreToProps) (SearchPage);