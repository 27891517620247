import React, { Component } from 'react';
import Laptop from '../components/navbar/Laptop';
import errorimg from '../img/errordrinks.png';
import  { Redirect } from 'react-router-dom';
import { HeadTitle } from '../components/global/Title';
import {Helmet} from 'react-helmet';

class ErrorPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
          home:false
      }
      this.LoadHome = this.LoadHome.bind(this);
    }

    LoadHome(){
        this.setState({home:true});
    }
    render() {
        if (this.state.home) {
            return <Redirect to='/' /> 
        }
        return (
            <>
            <Helmet>
                    <title>
                        {HeadTitle} Content not found
                    </title>
                    <meta name="theme-color" content="#00008C" />
                </Helmet>
            <Laptop />
            <div className="container-fluid App-header">
                     <div>
                           <center>
                               <img src={errorimg} alt="Content Not Found" />
                               <h4 className="text-danger">Something went wrong!</h4>
                               <p>The content you are looking for is no longer available.</p>
                               <button onClick={this.LoadHome} className="btn shadow text-light bg-st-primary st-errorbtn"><i className="fa fa-arrow-circle-left"></i>  Return Back</button>
                           </center>
                       </div>
            </div>
            </>
        );
    }
}

export default ErrorPage;