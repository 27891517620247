import React, { Component } from 'react';

class Scroller extends Component {
    render() {
        return (
            <div className="st-scroller" onClick={this.props.scrollup}>
                <span className="fa fa-upload"></span>
            </div>
        );
    }
}

export default Scroller;