export const fetch_post = () => {
    return {
        type: "SEARCH_OPENING"
    };
};

export const receive_post = post => {
    return {
        type: "SEARCH_OPENED",
        data: post
    };
};

export const receive_error = () => {
    return {
        type: "RECEIVE_ERROR"
    };
};

export const thunk_action_creator_search = name => {
    //store.dispatch(fetch_post());
    return function(dispatch, getState) {
        return dispatch(receive_post(name))


    };
};