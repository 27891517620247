import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages.css';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import axios from 'axios';
import Boxes from '../components/containers/loader/Boxes';
import  { Redirect } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import './pages.css';
import {Home} from '../components/global/Home';


class AdminDrinkTypesSub extends Component {
  
  constructor(props){
     super(props);
     this.state = {
        loading:true,
        clicked: true,
        counter: 0,
        counter2: 0,
        status: true,
        winetypes: [],
        message: [],
        name: "",
        pass: "",
        age:"",
        nameError: "",
        passError: "",
        ageError:"",
        formReady:true
     }
     this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    axios.get(Home+`Wintypes`)
      .then(res => {
         const winetypes = res.data;
         this.setState({ winetypes });
      })
      .catch(err =>console.log(err));
  }

handleChange = (event)=>{
  this.setState({[event.target.name]: event.target.value});
  
}

ChaneBTN =(bool)=>{
   this.setState({formReady:bool});
}

handleError = ()=>{
  this.ChaneBTN(false);
  let name = this.state.name;
  let age = this.state.age;
  if (name === "") {
    this.setState({nameError: 'Name is required'});
  }else{
    this.setState({nameError: ''});
  }

  if (age === "") {
    this.setState({ageError: 'age is required'});
  }else{
    this.setState({ageError: ''});
  }
  
  if (this.state.nameError !=="" || this.state.ageError !=="") {
    return false;
  }else{
    return true;
  }

}
handleSubmit = (event)=>{
  event.preventDefault();
  const check = this.handleError();

  if (check) {
    if(this.state.counter !== this.state.counter2){
      this.setState({counter2: this.state.counter2-1});
     }
    this.setState({clicked:false});
    axios.post(Home+`newWintypesSub`, 
    { 
      parent: this.state.age,
      name: this.state.name
     })
     .then(res => {
     
      this.setState({loading: false,counter2: this.state.counter2+1});
     if(res.status ===200 && res.data.success){

          toast.success(res.data.message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            });
       
     }else{
      this.setState({
        logged:true,
        status:false,
        message:res.data.message});

        toast.error(res.data.message,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
          });
     }
    }).catch(err =>console.log(err));
   }else{
     alert('error');
   }
  
}

   
    render() {
        if(!localStorage.getItem('userDetails')){
          return <Redirect to='/login' />
        }
        // if(localStorage.getItem('userDetails')){
        //    var userData = JSON.parse(localStorage.getItem('userDetails'));
        // }
        
        return (
            <div>
                <Laptop />
                <div className="container-fluid">
                <ToastContainer 
                 className='toast-container'
                 toastClassName="dark-toast-error"
                 position="top-right"
                 autoClose={55000}
                 hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
               />

                    <div className="row mt-5 mb-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <br></br>
                            <div className="card st-shadow mt-5">
                                <div className="card-header st-bg">
                                    <h5>Post Sub Category</h5>
                                </div>
                                <div className="st-empty2"></div>
                                <div className="card-body">
                                  <form onSubmit={this.handleSubmit}>
                                  <div className="form-group">
                                      <select className="form-control" name="age" onChange={this.handleChange}>
                                        <option value="">Select One</option>
                                        {this.state.winetypes.map(wines=>
                                         <option key={wines.id} value={wines.id}>{wines.name}</option>
                                          )}
                                      </select>
                                      <span className="text-danger">{this.state.ageError}</span>
                                    </div>

                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-book"></span></div>
                                       </div>
                                      <input 
                                      onBlur={this.handleError}
                                      value={this.state.name}
                                      name="name"
                                      type="text" 
                                      className="form-control radius" 
                                      placeholder="Your Name"
                                      onChange={this.handleChange} />
                                    </div>
                                    <span className="text-danger">{this.state.nameError}</span>
                                    </div>
                                    
                                    <div className="form-group">
                                    {(this.state.clicked ===false && this.state.loading ===true)||(this.state.clicked ===false && this.state.counter === this.state.counter2)? <center> <Boxes /> </center>:
                                      <button disabled={this.state.formReady} className="btn btn-block st-bg-d">Submit</button>
                                    }
                                    </div>
                                  </form>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
                <Footer st_class="footer2" />
            </div>
        );
    }
}


export default AdminDrinkTypesSub;