import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages.css';
import Laptop from '../components/navbar/Laptop';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Footer from '../components/containers/layout/Footer';
import axios from 'axios';
import Boxes from '../components/containers/loader/Boxes';
import  { Redirect } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import './pages.css';
import {Home} from '../components/global/Home';


class AdminDrinkTypes extends Component {
  
  constructor(props){
     super(props);
     this.state = {
        loading:true,
        clicked: true,
        counter: 0,
        counter2: 0,
        status: true,
        message: [],
        userData: []
     }
  }

//   componentDidMount() {
//     if(localStorage.getItem('userDetails')){
//        this.setState({userDetails: JSON.parse(localStorage.getItem('userDetails'))})
//     }
//   }

   
    render() {
        if(!localStorage.getItem('userDetails')){
          return <Redirect to='/login' />
        }
        if(localStorage.getItem('userDetails')){
           var userData = JSON.parse(localStorage.getItem('userDetails'));
        }
        
        return (
            <div>
                <Laptop />
                <div className="container-fluid">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <br></br>
                            <div className="card st-shadow mt-5">
                                <div className="card-header st-bg">
                                <h5>Post Category</h5>
                                </div>
                                <div className="st-empty2"></div>
                                <div className="card-body">
                                    
                                    <Formik
      initialValues={{ name: userData.name, type: '' }}
      validate={values => {
        let errors = {};
        if (!values.name) {
          errors.name = 'Name Required';
        }
        if (values.type === "") {
            errors.type = 'Type is Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if(this.state.counter !== this.state.counter2){
          this.setState({counter2: this.state.counter2-1});
         }
        this.setState({clicked:false});

        setTimeout(() => {
          
          axios.post(Home+`newWintypes`, 
          { 
            name: values.name,
            type: values.type
           })
           .then(res => {
           
            this.setState({loading: false,counter2: this.state.counter2+1});
           if(res.status ===200 && res.data.success){
  
                toast.success(res.data.message,{
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                  });
             
           }else{
            this.setState({
              logged:true,
              status:false,
              message:res.data.message});

              toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
                });
           }
          }).catch(err =>console.log(err));
          // alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {( {values, isSubmitting }) => (
        <Form>
  <ToastContainer 
    className='toast-container'
    toastClassName="dark-toast-error"
    position="top-right"
    autoClose={55000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
  />
           
            <div className="form-group mb-4">
            <div className="input-group">
               <div className="input-group-prepend">
                 <div className="input-group-text st-bg-d text-light"><span className="fa fa-envelope"></span></div>
                </div>
                <Field type="text" value={values.name} name="name" className="form-control form-r" placeholder="Your Name Should Be Here" />
            </div>
            <ErrorMessage readonly="" name="name" className="text-danger" component="div" /> 
            </div>
            <div className="form-group mb-4">
                <div className="input-group">
                   <div className="input-group-prepend">
                    <div className="input-group-text st-bg-d text-light"><span className="fa fa-key"></span></div>
                   </div>
                 <Field type="text" name="type" className="form-control form-r" placeholder="Enter New Type" />
                </div>
            <ErrorMessage name="type" className="text-danger" component="div"  />
            </div>
          
            {(this.state.clicked ===false && this.state.loading ===true)||(this.state.clicked ===false && this.state.counter === this.state.counter2)? <center> <Boxes /> </center>:
          <button type="submit" disabled={isSubmitting} className="btn st-bg-d btn-block">
            Loin Now
          </button>
          }
          <a className="text-dark" href="/whocodedpost/drinktypes/sub" style={{textAlign:'center'}}>Add Sub Categoryies</a>
        </Form>
        
      )}
    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
                <Footer st_class="footer2" />
            </div>
        );
    }
}


export default AdminDrinkTypes;