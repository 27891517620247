import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages.css';
import Laptop from '../components/navbar/Laptop';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Footer from '../components/containers/layout/Footer';
import axios from 'axios';
import Boxes from '../components/containers/loader/Boxes';
import  { Redirect } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import './pages.css';
import {Home} from '../components/global/Home';
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';


class Login extends Component {
  
  constructor(props){
     super(props);
     this.state = {
        loading:true,
        clicked: true,
        counter: 0,
        counter2: 0,
        status: true,
        message: []
     }
  }

   
    render() {
        if(localStorage.getItem('userDetails')){
          return <Redirect to='/' />
      }

        return (
            <div>
              <Helmet>
                <title>{HeadTitle}Login Page</title>
                <meta name="description" content="Login to checkdrinks" />
              </Helmet>
                <Laptop />
                <div className="container-fluid">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <br></br>
                            <div className="card st-shadow mt-5">
                                <div className="card-header st-bg">
                                    <div className="st-empty"></div>
                                </div>
                                <div className="st-empty2"></div>
                                <div className="card-body">
                                    
                                    <Formik
      initialValues={{ email: '', password: '' }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Email Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (values.password === "") {
            errors.password = 'Password Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if(this.state.counter !== this.state.counter2){
          this.setState({counter2: this.state.counter2-1});
         }
        this.setState({clicked:false});

        setTimeout(() => {
          
          axios.post(Home+`newlogin`, 
          { 
            email: values.email,
            password: values.password
           })
           .then(res => {
           
            this.setState({loading: false,counter2: this.state.counter2+1});
           if(res.status ===200 && res.data.success){

             var userDetails = {
              "id": res.data.userDetails.id,
              "name":res.data.userDetails.name,
              "email": res.data.userDetails.email,
              "number":res.data.userDetails.number,
              "state":res.data.userDetails.state,
              "country":res.data.userDetails.country,
             };
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            this.setState({logged:true});
           }else{
            this.setState({
              logged:true,
              status:false,
              message:res.data.message});

              toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
                });
           }
          })
          // alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
  <ToastContainer 
    className='toast-container'
    toastClassName="dark-toast-error"
    position="top-right"
    autoClose={55000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
  />
           
            <div className="form-group mb-4">
            <div className="input-group">
               <div className="input-group-prepend">
                 <div className="input-group-text st-bg-d text-light"><span className="fa fa-envelope"></span></div>
                </div>
                <Field type="email" name="email" className="form-control form-r" placeholder="Enter Your Email Address" />
            </div>
            <ErrorMessage name="email" className="text-danger" component="div" /> 
            </div>
            <div className="form-group mb-4">
                <div className="input-group">
                   <div className="input-group-prepend">
                    <div className="input-group-text st-bg-d text-light"><span className="fa fa-key"></span></div>
                   </div>
                 <Field type="password" name="password" className="form-control form-r" placeholder="Choose a Password" />
                </div>
            <ErrorMessage name="password" className="text-danger" component="div"  />
            </div>
          
            {(this.state.clicked ===false && this.state.loading ===true)||(this.state.clicked ===false && this.state.counter === this.state.counter2)? <center> <Boxes /> </center>:
          <button type="submit" disabled={isSubmitting} className="btn st-bg-d btn-block">
            Loin Now
          </button>
          }
        </Form>
        
      )}
    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
                <Footer st_class="footer2" />
            </div>
        );
    }
}


export default Login;