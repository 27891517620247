import store from "../store";
import axios from 'axios';

export const fetch_post = () => {
    return {
        type: "SEARCH_MORE"
    };
};

export const receive_post = post => {
    return {
        type: "FETCHED_SEARCH_MORE",
        data: post
    };
};

export const receive_paginate = post => {
    return {
        type: "RECEIVE_PAGINATE",
        data: post
    };
};

export const receive_error = () => {
    return {
        type: "RECEIVE_ERROR"
    };
};



export const thunk_action_creator_search_more = name => {

    store.dispatch(fetch_post());
    return function(dispatch, getState) {
        return axios.get(name)
            .then(res => {
                const nextPage = res.data[0].nextPageUrl;
                dispatch(receive_post(res.data));
                dispatch(receive_paginate(nextPage))
            }).catch(err => dispatch(receive_error()));


    };
};