import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class LoggOutBtn extends Component {
    render() {
        return (
            <div>
                <button className="btn btn-danger" onClick={this.props.logout}>Log Out</button>
            </div>
        );
    }
}

export default LoggOutBtn;