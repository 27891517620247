import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import {useParams} from "react-router";
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';
import ReportContainer from'../components/containers/cards/ReportContainer';

function Report() {
    const {id} = useParams();
  return (
    <div>
      <Helmet>
      <title>{HeadTitle}Report a drink</title>
                <meta name="description" content="Any thing wrong with this drink?. Report if any" />
      </Helmet>
      <Laptop />
    
    <div>
      <ReportContainer id={id} />
    </div>
      
    <Footer />
    </div>
    
  );
}

export default Report;
