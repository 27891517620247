import React, { Component } from 'react';
import Popup from "reactjs-popup";
import './modal.css';
import axios from 'axios';
import {Home} from '../../global/Home';
import Index from '../sharebutton/Index';

class Modal extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: [],
            front_image: [],
            company: [],
            back_image: [],
            name: [],
            desc: [],
            country:[],
            url:[],
            isLoading: true
        }
        this.LoadData = this.LoadData.bind(this);
    }

LoadData(id){
   this.setState({isLoading:true});
    axios.get(Home+`HomeDrinks/`+id)
          .then(res => {
            const ModalData = res.data;
            this.setState({ 
                id: ModalData.id,
                front_image: ModalData.front_image,
                company: ModalData.company,
                name: ModalData.name,
                desc: ModalData.desc,
                back_image: ModalData.back_image,
                country: ModalData.country,
                url: ModalData.url,
                isLoading: false
            });
          }).catch(err =>console.log(err));

        this.setState({ open: true});

}

    render() {
        return (
            <>
              <Popup onOpen={()=>this.LoadData(this.props.data)} onClose={this.props.close} open={this.props.open} position="right top" modal>
                {this.state.isLoading? 
                <div className="text-center st-empty" style={{paddingBottom:'5%',paddingTop:'5%',paddingLeft:'10px',paddingRight:'10px'}}>
                <div className="spinner-border" role="status" aria-hidden="true"></div>
              </div>
                :
                
                
              <div className="row no-gutters">
            <div className="col-md-12" key={this.state.id}>
                  <span className="st-calcel" onClick={this.props.close}>X</span>
               <div className="">
               <div className="card">
                 <div className="row no-gutters">
                     <div className="col-md-6 st-mobile-grid" style={{paddingRight: '1.2px'}}>
                     <img src={this.state.front_image} className="card-img img-thumbnail st-thumbnail" alt="..." />
                      <div className="card-img-overlay">
                      <span className="badge badge-danger" style={{float: 'right'}}>{this.state.company}</span>
                      </div>
                     </div>
                     <div className="col-md-6 st-mobile-grid" style={{paddingLeft: '1.2px'}}>
                     <img src={this.state.back_image} className="card-img img-thumbnail st-thumbnail" alt="..." />
                     </div>
                 </div>
                 <div className="card-body">
            <h4 className="card-title">{this.state.name}</h4>
            <p className="card-text">{this.state.desc}</p>
            <ul className="list-group">
            <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
          Company Name
           <span className="text-primary">{this.state.company}</span>
            </li>
           <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
            Country
        <span className="text-primary">{this.state.country}</span>
            </li>
       <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
       Website
    <span className="text-primary"><a href={this.state.url} rel="noopener noreferrer" target="_blank">{this.state.company}</a></span>
      </li>
  
</ul>
                 </div>
                 <div className="card-footer bg-transparent st-border st-h-rotate" style={{padding: '0px'}}>
                    <Index 
                    title={this.state.name}
                    desc={this.state.desc.slice(0,120)}
                    url={'https://checkdrinks.com/drink/'+this.state.id}
                    stringhash={'drinks'} />
                </div>
               </div> 
            </div>
            </div>
         </div>
        }
              </Popup> 
            </>
        );
    }
}

export default Modal;