import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rightside from '../components/containers/layout/Rightside';
import Leftside from '../components/containers/layout/Leftside';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import SingleShowcase from '../components/containers/cards/SingleShowcase';
import {useParams} from "react-router";
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';

function SingleDrink() {
    const {id} = useParams();
  return (
    <div>
      <Helmet>
        <title> {HeadTitle} Single Drink </title>
        <meta name="description" content="Sigle drink" />
      </Helmet>
      <Laptop />
    <Leftside />
    
    <div id="content">
      <div className="row no-gutters">
          <div className="st-gutters">
           <SingleShowcase id={id} />
          </div>

      </div>
      
    <Footer />
    </div>
    <Rightside />
    
    </div>
  );
}

export default SingleDrink;
