import store from "../store";
import { Home } from '../../components/global/Home';
import axios from 'axios';


export const fetch_post = () => {
    return {
        type: "FETCH_SEARCH"
    };
};

export const receive_post = post => {
    return {
        type: "FETCHED_FILTER",
        data: post
    };
};

export const receive_error = () => {
    return {
        type: "RECEIVE_ERROR"
    };
};

export const thunk_action_creator_filter = name => {
    store.dispatch(fetch_post());
    return function(dispatch, getState) {
        return axios.get(Home + `searchtwo/` + name['name'] + `/` + name['filter'])
            .then(res => {
                dispatch(receive_post(res.data))
            }).catch(err => dispatch(receive_error()));


    };
};