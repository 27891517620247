import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages.css';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import axios from 'axios';
import Boxes from '../components/containers/loader/Boxes';
import  { Redirect } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import './pages.css';
import {Home} from '../components/global/Home';


class AdminPostWine extends Component {
  
  constructor(props){
     super(props);
     this.state = {
        loading:true,
        clicked: true,
        counter: 0,
        counter2: 0,
        status: true,
        country: [],
        Wines:[],
        message: [],
        dname: "",
        dtype: "",
        frontfile:"",
        backfile:"",
        dcountry:"",
        desc:"",
        company:"",
        companyError:"",
        descError:"",
        dnameError: "",
        dtypeError: "",
        dcountryError:"",
        backfileError: "",
        frontfileError:"",
        formReady:true,
        uploadprogress: null,
        url:''
     }
     this.handleChange = this.handleChange.bind(this);
     this.fileInput = React.createRef();
     this.fileInput2 = React.createRef();
  }

  LoadData(){
    axios.get(Home+`AllSub_Winetypes2`)
      .then(res => {
        const Wines = res.data;
        this.setState({ Wines });
      }).catch(err =>console.log(err))
  }

  componentDidMount() {
    axios.get(`https://restcountries.eu/rest/v2/all`)
      .then(res => {
         const country = res.data;
         this.setState({ country });
      })
      .catch(err =>console.log(err));

      this.LoadData();
  }

handleChange = (event)=>{
  if (event.target.type !== 'files') {
    this.setState({[event.target.name]: event.target.value});
  }else{
    this.setState({
      frontfile: this.fileInput.current.files[0],
      backfile: this.fileInput2.current.files[0]});
  }
  
  console.log(this.state);
  
}

ChaneBTN =(bool)=>{
   this.setState({formReady:bool});
}

handleError = (name)=>{
  this.ChaneBTN(false);


  if (name === 'dtype') {
      if (this.state[name] === "") {
        this.setState({dtypeError: 'Category is required'});
      }else{
        this.setState({dtypeError: ''});
      }
  }else if(name === 'dcountry'){
      if (this.state[name] === "") {
        this.setState({dcountryError: 'Country is required'});
      }else{
        this.setState({dcountryError: ''});
      }
  }else if(name === 'dname'){
      if (this.state[name] === "") {
        this.setState({dnameError: 'Name is required'});
      }else{
        this.setState({dnameError: ''});
      }
  }else if(name === 'country'){
      if (this.state[name] === "") {
        this.setState({dcountryError: 'Country is required'});
      }else{
        this.setState({dcountryError: ''});
      }
  }else if(name === 'desc'){
      if (this.state[name] === "") {
        this.setState({descError: 'Description is required'});
      }else{
        this.setState({descError: ''});
      }
  }else if(name === 'company'){
      if (this.state[name] === "") {
        this.setState({companyError: 'Company/Owne is required'});
      }else{
        this.setState({companyError: ''});
      }
  }else if(name === 'frontfile'){
      if (this.state[name] === "" || this.state[name].length < 1) {
        this.setState({frontfileError: 'Front File is required'});
      }else{
        this.setState({frontfileError: ''});
      }
  }else if(name === 'backfile'){
      if (this.state[name] === "" || this.state[name].length < 1) {
        this.setState({backfileError: 'Back File is required'});
      }else{
        this.setState({backfileError: ''});
      }
  }

  
  if (this.state.dtypeError !=="" || this.state.dcountryError !=="" || this.state.dnameError !=="" || this.state.frontfileError!==""|| this.state.backfileError!=="") {
    return false;
  }else{
    return true;
  }

}

handleSubmit = (event)=>{
  event.preventDefault();
  const check = this.handleError();

    if (this.fileInput.current.files.length !==0 || this.fileInput2.current.files.length !==0) {
        
      if (check) {

        let fd = new FormData();
        fd.append('frontfile',this.fileInput.current.files[0],this.fileInput.current.files[0].name);
        fd.append('backfile',this.fileInput2.current.files[0],this.fileInput2.current.files[0].name);
        fd.append('type',this.state.dtype);
        fd.append('country',this.state.dcountry);
        fd.append('name',this.state.dname);
        fd.append('description',this.state.desc);
        fd.append('company',this.state.company);
        fd.append('url',this.state.url);

        if(this.state.counter !== this.state.counter2){
          this.setState({counter2: this.state.counter2-1});
         }
        this.setState({clicked:false});
        axios.post(Home+`upload`,fd,{
          onUploadProgress: ProgressEvent =>{
            let MyProgress = Math.round(ProgressEvent.loaded/ProgressEvent.total *100);
    
            if (MyProgress <=20) {

              toast.error('Uploading '+MyProgress+" %...",{
                position: "top-right",
                autoClose: 1000 - MyProgress,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });

            }else if(MyProgress >=21 && MyProgress < 45){
              toast.warn('Uploading '+MyProgress+" %...",{
                position: "top-right",
                autoClose: 1000 - MyProgress,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
            }else if(MyProgress >=46 && MyProgress < 65){
              toast.info('Uploading '+MyProgress+" %...",{
                position: "top-right",
                autoClose: 1000 - MyProgress,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
            }else if(MyProgress >=67){
              toast.success('Uploading '+MyProgress+" %...",{
                position: "top-right",
                autoClose: 1000 - MyProgress,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
            }
            //console.log('Progress: '+ Math.round(ProgressEvent.loaded/ProgressEvent.total *100)+" %");
            
          }
        })
         .then(res => {
          this.setState({loading: false,counter2: this.state.counter2+1});
         if(res.status ===200 && res.data.success){
    
              toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
                });
           
         }else{
          this.setState({
            logged:true,
            status:false,
            message:res.data.message});
    
            toast.error(res.data.message,{
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
              });
         }
        }).catch(err =>console.log(err));


      }
    }

    
  
  
}

   
    render() {
        if(!localStorage.getItem('userDetails')){
          return <Redirect to='/login' />
        }
        // if(localStorage.getItem('userDetails')){
        //    var userData = JSON.parse(localStorage.getItem('userDetails'));
        // }
        
        return (
            <div>
                <Laptop />
                <div className="container-fluid">
                <ToastContainer 
                 className='toast-container'
                 toastClassName="dark-toast-error"
                 position="top-right"
                 autoClose={55000}
                 hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
               />

                    <div className="row mt-5 mb-5">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <br></br>
                            <div className="card st-shadow mt-5">
                                <div className="card-header st-bg">
                                    <h5>Post New Wine</h5>
                                </div>
                                <div className="st-empty2"></div>
                                <div className="card-body">
                                  <form onSubmit={this.handleSubmit}>
                                  <div className="form-group">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-cab"></span></div>
                                       </div>
                                      <select required onBlur={()=>this.handleError('dtype')} className="form-control" name="dtype" onChange={this.handleChange}>
                                        <option value="">Select Drink Type</option>
                                        {this.state.Wines.map(childs=>
                                        <option key={childs.child_id} value={childs.child_id}>
                                              {childs.child} --- {childs.parent}
                                              </option> 
                                          )}
                                      </select>
                                      </div>
                                      <span className="text-danger">{this.state.dtypeError}</span>
                                    </div>

                                  <div className="form-group">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-location-arrow"></span></div>
                                       </div>
                                      <select required onBlur={()=>this.handleError('dcountry')} className="form-control" name="dcountry" onChange={this.handleChange}>
                                        <option value="">Select Country</option>
                                        <option value="International">International</option>
                                        {this.state.country.map(wines=>
                                         <option key={wines.name} value={wines.name}>{wines.name}</option>
                                          )}
                                      </select>
                                      </div>
                                      <span className="text-danger">{this.state.dcountryError}</span>
                                    </div>

                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-book"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('dname')}
                                      value={this.state.name}
                                      name="dname"
                                      type="text" 
                                      className="form-control radius" 
                                      placeholder="Name of Wine"
                                      onChange={this.handleChange}
                                      required />
                                    </div>
                                    <span className="text-danger">{this.state.dnameError}</span>
                                    </div>
                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-commenting-o"></span></div>
                                       </div>
                                      <textarea 
                                      className="form-control" 
                                      name="desc" 
                                      onBlur={()=>this.handleError('desc')}
                                      value={this.state.desc}
                                      onChange={this.handleChange}
                                      placeholder="Start Typing ....."
                                      required
                                      />
                                      </div>
                                      <span className="text-danger">{this.state.descError}</span>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6">
                                        <div className="form-group">
                                        <label className="col-form-label">Front View</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-image"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('frontfile')}
                                      ref={this.fileInput}
                                      name="frontfile"
                                      type="file" 
                                      className="form-control radius" 
                                      onChange={this.handleChange} 
                                      required />
                                    </div>
                                    <span className="text-danger">{this.state.frontfileError}</span>
                                    </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                        <label className="col-form-label">Back View</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-image"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('backfile')}
                                      ref={this.fileInput2}
                                      name="backfile"
                                      type="file" 
                                      className="form-control radius" 
                                      onChange={this.handleChange}
                                      required />
                                    </div>
                                    <span className="text-danger">{this.state.backfileError}</span>
                                    </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-home"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('company')}
                                      value={this.state.company}
                                      name="company"
                                      type="text" 
                                      className="form-control radius" 
                                      placeholder="Name of company  (Owner)"
                                      onChange={this.handleChange} 
                                      required />
                                    </div>
                                    <span className="text-danger">{this.state.companyError}</span>
                                    </div>

                                    <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                       <div className="input-group-text st-bg-d text-light">
                                         <span className="fa fa-globe"></span></div>
                                       </div>
                                      <input 
                                      onBlur={()=>this.handleError('company')}
                                      value={this.state.url}
                                      name="url"
                                      type="url" 
                                      className="form-control radius" 
                                      placeholder="Comany's website URL (eg. wwww.whocoded.com)"
                                      onChange={this.handleChange} 
                                     />
                                    </div>
                                    <span className="text-danger">{this.state.companyError}</span>
                                    </div>
                                    
                                    <div className="form-group">
                                    {(this.state.clicked ===false && this.state.loading ===true)||(this.state.clicked ===false && this.state.counter === this.state.counter2)? <center> <Boxes /> </center>:
                                      <button disabled={this.state.formReady} className="btn btn-block st-bg-d">Submit</button>
                                    }
                                    </div>
                                  </form>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <Footer st_class="footer2" />
            </div>
        );
    }
}


export default AdminPostWine;