import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Boxes from '../components/containers/loader/Boxes';
import axios from 'axios';
import './pages.css';
import  { Redirect } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';

class Register extends Component {
    constructor(props){
    super(props);
    this.state = {
       loading:true,
       clicked: true,
       counter: 0,
       counter2: 0,
       status: null,
       message: []
    }
 }

    render() {
      if(localStorage.getItem('userDetails')){
        return <Redirect to='/' />
    }
        return (
            <div>
              <Helmet>
                <title>
                  {HeadTitle} Register Now
                </title>
                <meta name="theme-color" content="#00008C" />
              </Helmet>
            <Laptop />
            <div className="container-fluid">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <br></br>
                            <div className="card st-shadow mt-5">
                                <div className="card-header st-bg">
                                    <div className="st-empty"></div>
                                </div>
                                <div className="st-empty2"></div>
                                <div className="card-body">
                                    
                                    <Formik
      initialValues={{ email: '', password: '', name: '', number: '' }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (values.password === "") {
            errors.password = 'Password Required';
        }
        if (values.name === "") {
            errors.name = 'Your Name is required';
        }
        if (values.number === "") {
            errors.number = 'Your Number is required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if(this.state.counter !== this.state.counter2){
          this.setState({counter2: this.state.counter2-1});
         }
         this.setState({clicked:false});
        setTimeout(() => {

          axios.post(`http://127.0.0.1:8000/api/register`, 
          { 
            name: values.name,
            email: values.email,
            number: values.number,
            password: values.password
           })
           .then(res => {
           
            this.setState({loading: false,counter2: this.state.counter2+1});
           console.log(res.data);
           if(res.status ===200 && res.data.success){
            //sessionStorage.setItem("userData", returndata);
            this.setState({
              logged:true,
              status:true,
              message:res.data.message});
           }else{
            this.setState({
              logged:true,
              status:false,
              message:res.data.message});
           }
          })
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {this.state.status===false && this.state.message !==""?
           <div className="alert alert-danger">{this.state.message}</div>: ""
           }
           {this.state.status===true && this.state.message !==""?
           <div className="alert alert-success">User Registered Successfully</div>: ""
           }
             <div className="form-group mb-4">
                <div className="input-group">
                   <div className="input-group-prepend">
                    <div className="input-group-text st-bg-d text-light"><span className="fa fa-user"></span></div>
                   </div>
                 <Field type="text" name="name" className="form-control form-r" placeholder="Enter Your FullName" />
                </div>
            <ErrorMessage name="name" className="text-danger" component="div" />
            </div>
            <div className="form-group mb-4">
                <div className="input-group">
                   <div className="input-group-prepend">
                    <div className="input-group-text st-bg-d text-light"><span className="fa fa-phone"></span></div>
                   </div>
                 <Field type="number" name="number" className="form-control form-r" placeholder="Enter Your Phone Number" />
                </div>
            <ErrorMessage name="number" className="text-danger" component="div" />
            </div>
            <div className="form-group mb-4">
            <div className="input-group">
               <div className="input-group-prepend">
                 <div className="input-group-text st-bg-d text-light"><span className="fa fa-envelope"></span></div>
                </div>
                <Field type="email" name="email" className="form-control form-r" placeholder="Enter Your Email Address"/>
            </div>
            <ErrorMessage name="email" className="text-danger" component="div" /> 
            </div>
           
            <div className="form-group mb-4">
                <div className="input-group">
                   <div className="input-group-prepend">
                    <div className="input-group-text st-bg-d text-light"><span className="fa fa-key"></span></div>
                   </div>
                 <Field type="password" name="password" className="form-control form-r" placeholder="Choose a Password" />
                </div>
            <ErrorMessage name="password" className="text-danger" component="div" />
            </div>
            {(this.state.clicked ===false && this.state.loading ===true)||(this.state.clicked ===false && this.state.counter === this.state.counter2)? <center> <Boxes /> </center>:
          <button type="submit" disabled={isSubmitting} className="btn st-bg-d btn-block">
            Loin Now
          </button>
            }
        </Form>
      )}
    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            <Footer st_class="footer2" />
            </div>
        );
    }
}

export default Register;