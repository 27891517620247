import React, { Component } from 'react';
import SearchHeader from './SearchHeader';
import {connect} from 'react-redux';
import  {Link } from 'react-router-dom';
import { thunk_action_creator_search_more } from '../../../store/actions/loadmoresearch';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Scroller from './Scroller';



class SearchResults extends Component {
    constructor(props) {
      super(props);
      this.state = {
         data:[],
         error:false,
         loading:false,
         sroller:false
      }
      this.loadmore = this.loadmore.bind(this);
      this.scrollhandler = this.scrollhandler.bind(this);
    }

    loadmore(){
      let nextPage = this.props.data.nextPage;
      if (nextPage.length > 0) {
        this.props.dispatch(thunk_action_creator_search_more(nextPage));
      }else{
        toast.error('Nothing more to load', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
      });
      }
    }

    componentDidMount(){
      document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.trackScrolling);
    }

    trackScrolling = () => {
      if (window.pageYOffset >= 200) {
        this.setState({sroller:true});
      }else{
        this.setState({sroller:false});
      }
    }

    scrollhandler(){
      window.scrollTo(0,0);
    }
  
    render() {
        let propsdrinks = this.props.data.drinks;
        if (propsdrinks.length === 0) {
          return <div className="row">
              
              <div className="col-lg-12 mt-3">
                 
              <SearchHeader />
              <div className="alert alert-warning text-center mt-3">
              <div className="st-empty"></div>
                <p>No drinks found. Please search another one</p>
                <div className="st-empty"></div>
                </div>
              </div>
          </div>;  
        }
       
        return (
            <>
            <SearchHeader />
            <ToastContainer 
              className='toast-container'
              toastClassName="dark-toast-error"
              position="top-right"
              autoClose={55000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            {this.state.sroller?
            <Scroller scrollup={this.scrollhandler} />
            :''}
            <div className="row no-gutters mt-3">
                {propsdrinks.map(drinks=>
                <div className={'col-lg-6 st-search-50'} key={drinks.id}>
                
                <div className="card mb-3" style={{maxWidth:'540px'}}>
                    <div className="row no-gutters">
                        <div className="col-md-4 st-search-30">
                        <Link to={'/drink/'+drinks.id}>
                        <img src={drinks.front_image} className="card-img search_img" alt={drinks.name} />
                        </Link>
                        </div>
                        <div className="col-md-8 st-search-70">
                        <div className="card-body" style={{padding:'0.50rem'}}>
                           <Link to={'/drink/'+drinks.id}>
                            <h5 className="card-title">{drinks.name}</h5>
                            </Link>
                            <p className="card-text">{drinks.desc.slice(0,60)}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                   
                </div>
                )}
                
            </div>
            <div className="mt-3 mb-5">
                {this.props.data.nextPage?
                <button onClick={this.loadmore} className="btn shadow btn-block bg-st-primary" style={{color:'white'}}>Load More 
                {this.props.data.isFetching?
                <span className="spinner-border spinner-border-sm" role="status" ></span>
                :''}
                </button>
                :''}
                </div>
            <div className="search-empty"></div>
            </>
        );
    }
}

const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }

export default connect(mapStoreToProps) (SearchResults);