import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rightside from '../components/containers/layout/Rightside';
import Leftside from '../components/containers/layout/Leftside';
import Showcase from '../components/containers/cards/Showcase';
import Laptop from '../components/navbar/Laptop';
import Footer from '../components/containers/layout/Footer';
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../components/global/Title';

class HomePage extends Component {



  render(){
  return (
    <div>
      <Helmet>
        <title>
          {HeadTitle} Check all drinks
        </title>
        <meta name="description" content="All types/kinds of drinks worldwide" />
        <meta name="theme-color" content="#00008C" />
      </Helmet>
      <Laptop />
    <Leftside />
    
    <div id="content">
      <div className="row no-gutters">
          <div className="st-gutters">
           <Showcase />
          </div>

      </div>
      
    <Footer />
    </div>
    <Rightside />
    
    </div>
  );
}
}

export default HomePage;
