import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './card.css';
import Index from '../sharebutton/Index';
import axios from 'axios';
import {Home} from '../../global/Home';
import Modal from '../modal/Modal';
import SingleWineContainer from '../loader/SingleWineContaner';
import {Helmet} from 'react-helmet';
import { HeadTitle } from '../../global/Title';
import {Link} from 'react-router-dom';





class SingleShowcase extends Component {

    constructor(props){
        super(props);
        this.state = {
            drinks:[],
            open: false,
            ModalData:[],
            RelatedDrinks:[],
            initialPropsId: [],
            cardmenu:false,
            currentcard:0
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.CardMenu = this.CardMenu.bind(this);
    }

    openModal(id) {
        this.setState({ open: true, ModalData:id});
      }

    closeModal() {
        this.setState({ open: false });
      }

      RelatedDrinks(){
        let id = this.props.id;
        axios.get(Home+`RelatedDrink/`+id)
          .then(res => {
            const RelatedDrinks = res.data;
            this.setState({ RelatedDrinks });
            // console.log(this.state);
          }).catch(err =>console.log(err));

      }


    componentDidMount() {
         //console.log(this.props);
        let id = this.props.id;
        axios.get(Home+`HomeDrinks/`+id)
          .then(res => {
            const drinks = res.data;
            // console.log(res.data);
            this.setState({ drinks, initialPropsId:id });
          }).catch(err =>console.log(err));

          this.RelatedDrinks();
      }

      OnPropsChange(){
        let id = this.props.id;
          axios.get(Home+`HomeDrinks/`+id)
          .then(res => {
            const drinks = res.data;
            // console.log(res.data);
            this.setState({ drinks, initialPropsId:id });
          }).catch(err =>console.log(err));

          this.RelatedDrinks();
      }

    CardMenu(id){
        if (this.state.cardmenu) {
          this.setState({cardmenu:false,currentcard:0});
        }else{
          this.setState({cardmenu:true,currentcard:id});
        }
    }

    render() {
      let id = this.state.initialPropsId;
      let PropsId = this.props.id;
      if (id !== PropsId) {
        this.OnPropsChange();
      }
        if(this.state.drinks.length === 0 || id !== PropsId){
            return(
                <>
                 <SingleWineContainer />
                </>
            )
        }
       const drink = this.state.drinks;
        return (
          <>
          <Helmet>
            <title>
              {HeadTitle}{drink.name}
            </title>
            <meta name="description" content={drink.desc} />
            <meta name="theme-color" content="#00008C" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={drink.desc} />
            <meta property="og:url" content="https://checkdrinks.com/" />
            <meta property="og:site_name" content="Checkdrinks" />
            <meta property="og:title" content={drink.name} />
            <meta property="og:image" content={drink.front_image} />
            <meta property="og:image:secure_url" content={drink.front_image} />
          </Helmet>
            <div className="row no-gutters mobile-space">
            <Modal 
            close={this.closeModal} 
            open={this.state.open}
            data={this.state.ModalData} />
            
            <div className="col-md-12 st-mouse-hover" key={drink.id}>
               <div className="st-gutters">
               <div className="card mb-3 st-card-height">
                 <div className="row no-gutters">
                     <div className="col-md-6 st-mobile-grid" style={{paddingRight: '1.2px'}}>
                     <img src={drink.front_image} className="card-img img-thumbnail st-thumbnail-2" alt="..." />
                      <div className="card-img-overlay">
                      <span className="badge badge-danger" style={{float: 'right'}}>{drink.company}</span>
                      </div>
                     </div>
                     <div className="col-md-6 st-mobile-grid" style={{paddingLeft: '1.2px'}}>
                     <img src={drink.back_image} className="card-img img-thumbnail st-thumbnail-2" alt="..." />
                     <div className="st-top-right st-click" onClick={()=>this.CardMenu(drink.id)}><span className="fa fa-ellipsis-v st-color st-click" style={{color:'#696971'}}></span>
                      <div className="st-dropdown-content" style={{display:this.state.cardmenu && this.state.currentcard ===drink.id?'block':'none'}}>
                      <Link to={'/report/'+drink.id}>Report</Link>
                      </div>
                     </div>
                     </div>
                 </div>
                 <div className="card-body">
           <h4 className="card-title">{drink.name}</h4>
            <p className="card-text">{drink.desc}</p>
            <ul className="list-group">
            <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
             Name
           <span className="text-primary">{drink.company}</span>
            </li>
           <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
            Country
        <span className="text-primary">{drink.country}</span>
            </li>
       <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
      Website
    <span className="text-primary"><a href={drink.url} rel="noopener noreferrer" target="_blank">{drink.company}</a></span>
      </li>
      <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
       Uploaded By
    <span className="text-primary">comingsoon</span>
      </li>
  
</ul>
                 </div>
                 <div className="card-footer bg-transparent st-border st-h-rotate" style={{padding: '0px'}}>
                     <Index 
                     title={drink.name}
                     desc={drink.desc.slice(0,120)}
                     url={'https://checkdrinks.com/drink/'+drink.id}
                     stringhash={'drinks'} />
                </div>
               </div> 
            </div>
            </div>
         
           
         </div>
         <div className="row mb-4 no-gutters">
           {this.state.RelatedDrinks.map(Related =>
             <div className="col-md-3" key={Related.id}>
               <div className="st-gutters" style={{padding:'5px'}}>
               <div className="card related-sha">
               <img src={Related.front_image} className="card-img img-thumbnail st-thumbnail" alt="..." style={{boxShadow:'none',border:'0px'}} />
               <div className="card-footer">
               <div style={{fontWeight:'500'}}>{Related.name}</div>
               </div>
               </div>
               </div>
               
             </div>
            )}
         </div>
         </>
        );
    }
}

export default SingleShowcase;