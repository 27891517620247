import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {
      class: 'card st-bg-dark '
    }
  }
    render() {  
        return (
            <div className={this.state.class+this.props.st_class} >
                <ul className="nav nav-fill">
  <li className="nav-item">
    <a className="nav-link active" href="/">Home</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/about">About Us</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/feedback">Feedback</a>
  </li>
  <li className="nav-item">
  <a className="nav-link" href="#">Donate</a>
  </li>
</ul>
            </div>
        );
    }
}

export default Footer;