import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import '../layout/layout.css';

class LeftSide extends Component {
    constructor(props){
        super(props);
        this.state = {
            item: [
                'primary',
                'secondary',
                'success',
                'success2',
                'success3',
                'success4',
                'success5',
                'success6',
                'success7',
                'success8',
                'success9',
                'success10',
                'success11',
                'success12'
              ]
            }
    }
    render() {
        
        return (
            <div>
                <ul className="list-unstlye components st-list">
            <li className="no-ho">
              <p className="side-head l-title3 shine" style={{padding: '1px 5px 1px 10px'}}></p>
            </li>
            {this.state.item.map(person =>
              <li key={person}>
               <p className="shine mb-1"><span className="l-stimg" style={{border: '0px solid black'}}></span> <span className="l-title5"></span>
               </p>
            </li>
              )}
          </ul>
 </div>
        );
    }
}

export default LeftSide;