import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './laptop.css';
import {Link} from 'react-router-dom';
import LoggOutBtn from '../navbar/LoggOutBtn';
import checkdrinkLogo from '../../img/checkdrinks1.png';
import mobile from '../../img/mobile.png';
import menu from '../../img/menu.png';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import axios from 'axios';
import {Home} from '../global/Home';
import  { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { thunk_action_creator } from '../../store/actions/searchfilter';
import {thunk_action_creator_search} from '../../store/actions/opensearchmenu';
import {thunk_action_creator_lastname} from '../../store/actions/searchlastnameupdate';
import searchivon from '../../img/search.png';
import searchcancel from '../../img/cancel.png';

class Laptop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate:false,
      menu:false,
      options: [],
      search:"",
      move:false,
      loading:false,
      selected:false
    }
    this.ppppp = this.ppppp.bind(this);
    this.OpenMenu = this.OpenMenu.bind(this);
    this.handleRequestOptions = this.handleRequestOptions.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.OpenSearch = this.OpenSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.handleselect = this.handleselect.bind(this);
  }

  // text in input is "I want @ap"
  handleRequestOptions(data) {
    this.setState({loading:true});
    axios.post(Home+`searchone`, {
      search: data })
      .then(res => {
        this.setState({options:res.data,loading:false});
      })
      
  }  
  
  handleChange = (event)=>{
    let laststring = event.length;
    this.setState({search:event});
    //check if option is selected
    if (event.charAt(laststring-1) === "?") {
       if (this.state.search !=="") {
        this.props.dispatch(thunk_action_creator({'name':this.state.search,'filter':'all'}));
        this.props.dispatch(thunk_action_creator_lastname(this.state.search));
       }
    }

    
  }
  handleselect(){
    alert('cominf');
  }

  searchHandler(){
    if (this.state.search !=="") {
      //this.setState({move:true});
      this.props.dispatch(thunk_action_creator({'name':this.state.search,'filter':'all'}));
      this.props.dispatch(thunk_action_creator_lastname(this.state.search));
    }else{
      this.setState({move:false});
    }
    
  }

  ppppp(){
    if (this.state.rotate) {
      this.setState({rotate:false});
    }else{
      this.setState({rotate:true});
    }
    return this.state.rotate;
  }
  
  componentDidMount(){
    this.ppppp();
    this.interval = setInterval(() => this.ppppp(), 4000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  LoggOutBtnFunction =()=>{
    localStorage.removeItem('userDetails');
  }

  OpenMenu(){
    if (this.state.menu) {
      this.setState({menu:false});
    }else{
      this.setState({menu:true});
    }
    }

    OpenSearch(){
      this.props.dispatch(thunk_action_creator_search(true));
    }

    closeSearch(){
      this.props.dispatch(thunk_action_creator_search(false));
    }

    render() {

  
    if (this.props.data.redirect && !this.props.data.searchpage) {
      return <Redirect to={"/search/drinks"} />
    }

    let width = "";
    let padd  = "";
    let leftt = "";
    let rghtt = "";
    let bar   = "";
    if (window.innerWidth <= 1199 && window.innerWidth > 1049) {
      width+="container-fluid";
      padd+="st-nav-a";
      leftt+="";
      rghtt+="";
      bar+="";
    }else if(window.innerWidth <=1050){
      width+="container-fluid";
      padd+="st-nav-a2";
      leftt+="st-20";
      rghtt+="st-40";
      bar+="st-sss";
    }else{
      width+="container";
      padd+="";
      leftt+="";
      rghtt+="";
      padd+="";
      bar+="";
    }
   
        return (
            <div style={{zIndex:'1111111111111111'}}>
               <div className="bg-st-primary sticky st-shadow2 st-desct" style={{zIndex:'11111'}}>
                   <div className={width}>
                     <div className="row no-gutters">
                       <div className={'col-lg-2 '+leftt}>
                       <Link to="/" className="navbar-brand"><img src={checkdrinkLogo} alt="Checkdrinks Logo" /></Link>
                       </div>
                       <div className={'col-lg-5 '+rghtt}>
                       <div className={bar}>
                         
                       <TextInput
                       minChars={1}
                       spacer="?"
                       name="search"
                       matchAny={true}
                        Component="input"
                        className="form-control form-control-sm"
                        placeholder="Start typing... Examples: malt, Guiness"
                        trigger=''
                        requestOnlyIfNoOptions={true}
                        onRequestOptions={this.handleRequestOptions}
                        options={this.state.options}
                        style={{marginTop: '10px',marginLeft:'10px'}}
                        onChange={this.handleChange}
                        />
                        
                        {this.state.loading || this.props.data.isFetching?<>
                        <span className="spinner-border spinner-border-sm st-search-icon" role="status" aria-hidden="true"></span>
                        <span className="sr-only st-search-icon">Loading...</span>
                        </>:
                        <span onClick={this.searchHandler} className="fa fa-search st-card-icon st-search-icon"></span>
                        }
                       </div>
                       </div>
                       <div className={'col-lg-5 '+rghtt}>
                      <ul className="st">

                        {/* <li>
                      <Link to="/home" className={padd}>Home</Link>
                        </li> */}
                        <li>
                        <Link to="/about" className={padd}>About Us</Link>
                        </li>
                        <li>
                        <Link to="/feedback" className={padd}>Send FeedBack</Link> 
                        </li>
                        {!localStorage.getItem('userDetails')?<>
                        <li>
                        <Link to="/register" style={{padding: '.25rem .5rem',height:'fit-content',marginTop: '10px',}} className="btn btn-warning" >Register</Link>
                        </li>
                        <li>
                        <Link to="/login" className="btn btn-warning" style={{marginLeft:'5px',padding: '.25rem .5rem',height:'fit-content',marginTop: '10px',}}>Login</Link>
                        </li></>:
                         <>
                         <li>
                         <LoggOutBtn logout={()=>this.LoggOutBtnFunction()} />
                         </li>
                         </>}
                      </ul>
                       </div>
                     </div>
                   
  

  {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse style={{marginLeft: '-60px',}} id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Link to="/home" className="nav-link">Home</Link>
      <Link to="/about" className="nav-link">About Us</Link>
      <Link to="/feedback" className="nav-link">Send FeedBack</Link> 
    </Nav>
    <Form inline>
    {!localStorage.getItem('userDetails')?<>
    <Link to="/register" className="btn btn-warning" >Register</Link>
    <Link to="/login" className="btn btn-warning" style={{marginLeft:'5px'}}>Login</Link>
    </>:
    <><LoggOutBtn logout={()=>this.LoggOutBtnFunction()} /></>}
    </Form>
  </Navbar.Collapse> */}
  </div>
</div>
{this.props.data.searchopen?
<div className=" bg-st-primary sticky st-shadow2 st-mobile">
<div style={{zIndex:'11111111',display:'flex'}}>
    <div style={{width:'90%'}}>
    <div style={{marginLeft:'0px',marginRight:'2%'}}>
    <TextInput
       spacer=""
       name="search"
       matchAny={true}
        Component="input"
        className="form-control form-control-lg"
        placeholder="Start typing..."
        trigger=''
        requestOnlyIfNoOptions={true}
        onRequestOptions={this.handleRequestOptions}
        options={this.state.options}
        style={{marginTop: '0px',borderRadius: '0px',}}
        onChange={this.handleChange}
        />
        
        {this.state.loading || this.props.data.isFetching?<>
        <span style={{marginTop:'-35px'}} className="spinner-border spinner-border-sm st-search-icon" role="status" aria-hidden="true"></span>
        <span style={{marginTop:'-35px'}} className="sr-only st-search-icon">Loading...</span>
        </>:
        <span onClick={this.searchHandler} style={{marginTop:'-35px',fontSize:'1.4rem'}} className="fa fa-search st-card-icon st-search-icon"></span>
        }
        </div>
    </div>
    <div style={{width:'10%'}}>
    <span className="nav-link" onClick={this.closeSearch}>
       <i className="fa fa-remove st-new-x" style={{marginTop:'5px',transition:'1s',transform:'scale(1.1)'}}></i>
   </span>
    </div>
  </div>
</div>
  :
<div className=" bg-st-primary sticky st-shadow2 st-mobile">
 
<ul className="nav nav-pills nav-fill" id="mobile">
  <li className="nav-item">
    <Link to="/" className="nav-link" style={{paddingBottom:'0px'}}>
    <img src={mobile} alt="Checkdrinks Logo" style={{marginTop:'-5px'}} />
    </Link>
  </li>
  <li className="nav-item">
  <Link to="/about" className="nav-link">About Us</Link>
  </li>
  <li className="nav-item">
  <Link to="/feedback" className="nav-link">FeedBack</Link>
  </li>
  <li className="nav-item" onClick={this.OpenSearch}>
  <span className="nav-link">
  {/* <img src={searchivon} alt="Checkdrinks Search" style={{marginTop:'-5px',transition:'1s'}} /> */}
  <i className="fa fa-search" style={{fontSize:'22px'}}></i>
  </span>
  </li>
  <li className="nav-item" onClick={this.OpenMenu}>
  <span className={+this.state.rotate?'nav-link st-360':'nav-link st-zero'}>
  <i className="fa fa-bars" style={{fontSize:'22px',transition:'1s'}}></i>
  {/* <img src={menu} className={this.state.rotate?'st-360':'st-zero'} alt="Checkdrinks Menu" style={{marginTop:'-5px',transition:'1s'}} /> */}
  </span>
  </li>
</ul>
</div>
    }
   
    <div style={{display:this.state.menu?'block':'none'}} className="st-mobile2 bg-st-primary text-light">
        <nav className="nav flex-column st-www">
        <a className="nav-link disabled st-ccc" href="/#" style={{fontWeight: '600'}}>Types of Drinks</a>
          <Link to="/types/alcoholic_drinks" className="nav-link active">Alcoholic Drinks</Link>
          <Link to="/types/non_alcoholic_drinks" className="nav-link active">Non-Alcoholic Drinks</Link>
          <a className="nav-link disabled st-ccc" href="/#" style={{fontWeight: '600'}}>Other Menu</a>
          <Link className="nav-link" to="/feedback">Send FeedBack</Link>
          <a className="nav-link active" href="/#">Contact Us</a>
          <a className="nav-link" href="/#">Link</a>
          <a className="nav-link" href="/#">Link</a>
          <a className="nav-link disabled st-ccc" href="/#" >Disabled</a>
          <a className="nav-link active" href="/#">Active</a>
          <a className="nav-link" href="/#">Link</a>
          <a className="nav-link" href="/#">Link</a>
          
         </nav>
    </div>
            </div>
        );
    }
}

const mapStoreToProps = (store) =>{
  return{
     data:store
  }
}

export default connect(mapStoreToProps) (Laptop);